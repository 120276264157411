import { Box, Popover } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DbgButtonSize, Permission, UserType } from '../../../../../models/enums';
import { getTransparentButtonStyleProps } from '../../../../../styles/themes';
import { DbgRoundedButton } from '../../../../components/buttons/DbgRoundedButton';
import { defaultColors } from '../../../../../styles/variables';
import { contractTermService } from '../../../../../services';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DbgLinkButton } from '../../../../components/buttons/DbgLinkButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '../../../../layout/PageRouter';
import { DbgLoadingSpinner } from '../../../../components/shared/DbgLoadingSpinner';
import PermissionFilter from '../../../../components/shared/PermissionFilter';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { contractOrderSurveyService } from '../../../../../services/contract/contractOrderSurveyService';

interface IProps {
  contractId: number;
  allowOrderSurvey?: boolean;
  onOrderSurveyProcessed: () => void;
}

const styles = {
  root: {},
  button: {
    color: defaultColors.darkGrey,
    padding: '0px',
    width: 'initial',
    border: 'none',
    '&:hover': {
      color: defaultColors.blue,
      backgroundColor: defaultColors.tables.rowHover,
    },
  },
  popover: {
    backgroundColor: defaultColors.white,
    borderRadius: '12px',
    border: `1px solid ${defaultColors.grey}`,
  },
  popoverWrapper: {
    backgroundColor: defaultColors.transparent,
    borderRadius: '12px',
    boxShadow: `0 20px 20px -13px ${defaultColors.transparentMediumBlueGrey}`,
    width: '200px',
  },
  vendorContractNumber: {
    color: defaultColors.mediumGrey,
    fontFamily: 'Inter',
    fontSize: '13px',
    letterSpacing: 0,
    lineHeight: '19px',
    borderBottom: `1px solid ${defaultColors.grey}`,
    width: '100%',
    paddingBottom: '8px',
    overflow: 'hidden',
    marginBottom: '10px',
    fontWeight: 500,
    paddingLeft: '13px',
  },
  action: {
    padding: '9px 8px 9px 14px',
    width: '100%',
    textAlign: 'left',
    display: 'block',
    fontSize: '15px',
    fontWeight: 500,
    borderTop: `1px solid ${defaultColors.grey}`,
    color: defaultColors.darkGrey,
    height: '46px',
    lineHeight: '28px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: defaultColors.altGrey,
      color: defaultColors.blue,
    },
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  loadingIcon: {
    height: '17px !important',
    width: '17px !important',
    float: 'right',
    top: '5px',
    right: '10px',
    position: 'relative',
  },
};

export default function ContractActionMenuButton(props: IProps) {
  const [actionButton, setActionButton] = useState<HTMLButtonElement | null>(null);
  const showPopover = Boolean(actionButton);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { referenceData, user } = useApplicationContextState();

  const adPlannerExportEnabled = useMemo(() => {
    return referenceData?.adPlannerExportEnabled ?? false;
  }, [referenceData]);

  const processOrderSurveyEnabled = useMemo(() => {
    return user?.userType === UserType.Internal && props.allowOrderSurvey;
  }, [user]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setActionButton(event.currentTarget);
  };

  const cloneContract = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDisabled(true);
    const clone = await contractTermService.cloneContract(props.contractId);
    if (clone instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to clone contract.', { variant: 'error' });
      setDisabled(false);
      return;
    }

    setDisabled(false);
    navigate(RouteEnum.Contract, { state: { contract: clone } });
  };

  const adPlannerExport = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`${RouteEnum.AdPlannerExport}/${props.contractId}`);
  };

  const processOrderSurvery = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDisabled(true);
    const result = await contractOrderSurveyService.processOrderSurvey(props.contractId);
    if (result instanceof HttpErrorResponse) {
      enqueueSnackbar(result.message ?? 'Unable to Process Order Survey.', { variant: 'error' });
      setDisabled(false);
      return;
    } else {
      props.onOrderSurveyProcessed();
      enqueueSnackbar('Order Survey Processed.', { variant: 'success' });
      setDisabled(false);
      setActionButton(null);
    }
  };

  return (
    <PermissionFilter permission={Permission.TermsCreate}>
      <DbgRoundedButton
        id={`ContractActions${props.contractId}`}
        dbgButtonSize={DbgButtonSize.Large}
        styleProps={getTransparentButtonStyleProps()}
        sx={styles.button}
        onClick={handleOpen}
      >
        <MoreHorizIcon />
      </DbgRoundedButton>
      <Popover
        id={`ContractActionMenu${props.contractId}`}
        open={showPopover}
        anchorEl={actionButton}
        onClose={(event: Event) => {
          event.stopPropagation();
          event.preventDefault();
          setActionButton(null);
        }}
        sx={styles.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -145,
        }}
        slotProps={{
          paper: {
            style: styles.popoverWrapper,
          },
        }}
      >
        <Box sx={styles.popover}>
          <DbgLinkButton id={`CloneContract${props.contractId}`} sx={styles.action} onClick={cloneContract} disabled={disabled}>
            Clone Contract
            {disabled && <DbgLoadingSpinner id={`CloneContractLoading${props.contractId}`} sx={styles.loadingIcon} />}
          </DbgLinkButton>
          {adPlannerExportEnabled && (
            <PermissionFilter permission={Permission.AdPlannerExport}>
              <DbgLinkButton id={`AdPlannerExport${props.contractId}`} sx={styles.action} onClick={adPlannerExport} disabled={disabled}>
                Ad Planner Export
                {disabled && <DbgLoadingSpinner id={`CloneContractLoading${props.contractId}`} sx={styles.loadingIcon} />}
              </DbgLinkButton>
            </PermissionFilter>
          )}
          {processOrderSurveyEnabled && (
            <DbgLinkButton id={`AdPlannerExport${props.contractId}`} sx={styles.action} onClick={processOrderSurvery} disabled={disabled}>
              Order Survey Processed
              {disabled && <DbgLoadingSpinner id={`ProcessOrderSurveyLoading${props.contractId}`} sx={styles.loadingIcon} />}
            </DbgLinkButton>
          )}
        </Box>
      </Popover>
    </PermissionFilter>
  );
}
